<ng-container *transloco="let t">
  <div class="accordion" aria-label="Accordion">
    <label class="caret" for="{{ uniqueKey() }}">
      <ng-content select="[acc-open]"></ng-content>
      <!--
        t(ui.Close)
        t(ui.Open)
      -->
      <svg-icon
        src="/assets/icons/chevron-left-nocircle.svg"
        class="caret-arrow"
        [ngClass]="{ 'is-animating': isAnimating }"
        [attr.aria-label]="t('ui.' + (isOpen ? 'Close' : 'Open')) + ': ' + toggleText()"
        (click)="accordionClick(input.checked)"></svg-icon>
    </label>
    <!--
      t(ui.open)
      t(ui.closed)
    -->
    <input
      type="checkbox"
      #input
      (click)="accordionClick(input.checked)"
      id="{{ uniqueKey() }}"
      [checked]="isOpen"
      [attr.aria-label]="toggleText() + ': ' + t('ui.' + (isOpen ? 'open' : 'closed'))" />
    <div class="body" #collapsibleBody>
      <ng-content select="[acc-collapsible]"></ng-content>
    </div>
  </div>
</ng-container>
